<template>
  <div>
    <b-card>
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <h4>Situs Promo</h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="text-right"
          >
            <b-button
              variant="success"
              @click="$router.push({ name: 'tambah-promo'})"
            >
              + Tambah Promo
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col
          v-for="diskon in listPromo"
          :key="diskon.kuota"
          cols="12"
          sm="6"
        >
          <b-card
            border-variant="success"
          >
            <div>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <h6
                    class="text-primary font-weight-bolder"
                  >
                    {{ diskon.judul }}
                  </h6>
                  <p>
                    Batas : {{ diskon.batas_waktu.toDate().toDateString() }}
                  </p>
                  <p>
                    Potongan : {{ diskon.potongan }}
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="text-right"
                >
                  <b-badge
                    variant="success"
                  >
                    {{ diskon.kode_promo }}
                  </b-badge>
                  <br>
                  <small>
                    Kuota : {{ diskon.kuota }}
                  </small>
                  <br>
                  <b-button
                    variant="danger"
                    squared
                    size="sm"
                    class="mt-1"
                  >
                    Hapus
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BBadge, BButton,
} from 'bootstrap-vue'
import { db } from '@/firebase'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
    BButton,
  },
  data() {
    return {
      listPromo: [],
    }
  },
  created() {
    this.getPromo()
  },
  methods: {
    getPromo() {
      db.collection('promo').orderBy('mulai_waktu', 'desc').get().then(snapshot => {
        const arr = []
        snapshot.docs.forEach(doc => {
          arr.push({
            id: doc.id,
            judul: doc.data().judul,
            batas_waktu: doc.data().batas_waktu,
            kuota: doc.data().kuota,
            potongan: doc.data().potongan,
            kode_promo: doc.data().kode_promo,
          })
          this.listPromo = [...new Set(arr)]
        })
      })
    },
  },
}
</script>

<style>
</style>
